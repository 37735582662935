.swiper {
  width: 400px;
  height: 200px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 70%;
  height: 200px;
}

.swiper-slide img {
  display: block;
  width: 100%;
  /* height: 200px; */
  object-fit: contain;
}

.noImage {
  width: 400px;
  height: 200px;
  object-fit: cover;
  text-align: center;
  line-height: 200px !important;
}
