.uploadImage img {
  width: 100%;
  height: 100%;
  object-fit: cover !important;
  object-position: center;
}

.uploadImage {
  border: 1px solid #ccc;
}
