@keyframes brightDark {
  0% {
    color: white;
  }
  50% {
    color: black;
  }
  100% {
    color: white;
  }
}

.bright-dark-animation {
  animation: brightDark 3s infinite;
}
