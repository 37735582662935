.customize_box .MuiGrid-root .MuiIconButton-root {
  position: absolute !important;
}

.uploadImage img {
  object-fit: contain;
}

.uploadImage {
  border: 1px solid #ccc;
}
